// import actions
import marcaProductoAction from './actions/marca_producto'
import categoriaProductoAction from './actions/categoria_producto'
import unidadMedidaAction from './actions/unidad_medida'
import almacenAction from './actions/almacen'
import tipoProductoAction from './actions/tipo_producto'
import proveedorAction from './actions/proveedor'
import productoAction from './actions/producto'
import motivoMovimientoAction from './actions/motivo_movimiento'
import movimientoAction from './actions/movimiento'

const actions = { ...marcaProductoAction, ...categoriaProductoAction, ...unidadMedidaAction, ...almacenAction, ...tipoProductoAction, ...proveedorAction, ...productoAction, ...motivoMovimientoAction, ...movimientoAction }

const getters = { }

const mutations = { }

const state = { }

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
