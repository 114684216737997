export default [
  {
    path: '/app/caja/liquidacion',
    name: 'rt-caja-liquidacion',
    component: () => import('@/views/erp/caja/liquidacion/Liquidacion.vue'),
    meta: {
      resource: 'rt-caja-liquidacion',
      pageTitle: 'Liquidacion',
      breadcrumb: [
        {
          text: 'Cajas',
        },
        {
          text: 'Liquidacion',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/caja/liquidacionform',
    name: 'rt-caja-liquidacionform',
    component: () => import('@/views/erp/caja/liquidacion/LiquidacionForm.vue'),
    meta: {
      resource: 'rt-caja-liquidacionform',
      pageTitle: 'Caja',
      breadcrumb: [
        {
          text: 'Nueva Liquidacion',
          active: true,
        },
      ],
    },
  },
]
