export default [
  {
    path: '/app/logistica/almacen',
    name: 'rt-logistica-almacen',
    component: () => import('@/views/erp/logistica/almacen/AlmacenList.vue'),
    meta: {
      resource: 'rt-logistica-almacen',
      pageTitle: 'Almacén',
      breadcrumb: [
        {
          text: 'Logística',
        },
        {
          text: 'Almacén',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/logistica/unidad-medida',
    name: 'rt-logistica-unidad-medida',
    component: () => import('@/views/erp/logistica/unidad_medida/UnidadMedidaList.vue'),
    meta: {
      resource: 'rt-logistica-unidad-medida',
      pageTitle: 'Unidad de Medida',
      breadcrumb: [
        {
          text: 'Logística',
        },
        {
          text: 'Unidad de Medida',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/logistica/marca-producto',
    name: 'rt-logistica-marca-producto',
    component: () => import('@/views/erp/logistica/marca_producto/MarcaProductoList.vue'),
    meta: {
      resource: 'rt-logistica-marca-producto',
      pageTitle: 'Marca Producto',
      breadcrumb: [
        {
          text: 'Logística',
        },
        {
          text: 'Marca Producto',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/logistica/categoria-producto',
    name: 'rt-logistica-categoria-producto',
    component: () => import('@/views/erp/logistica/categoria_producto/CategoriaProductoList.vue'),
    meta: {
      resource: 'rt-logistica-categoria-producto',
      pageTitle: 'Categoría Producto',
      breadcrumb: [
        {
          text: 'Logística',
        },
        {
          text: 'Categoría Producto',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/logistica/tipo-producto',
    name: 'rt-logistica-tipo-producto',
    component: () => import('@/views/erp/logistica/tipo_producto/TipoProductoList.vue'),
    meta: {
      resource: 'rt-logistica-tipo-producto',
      pageTitle: 'Tipo Producto',
      breadcrumb: [
        {
          text: 'Logística',
        },
        {
          text: 'Tipo Producto',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/logistica/proveedor',
    name: 'rt-logistica-proveedor',
    component: () => import('@/views/erp/logistica/proveedor/ProveedorList.vue'),
    meta: {
      resource: 'rt-logistica-proveedor',
      pageTitle: 'Proveedor',
      breadcrumb: [
        {
          text: 'Logística',
        },
        {
          text: 'Proveedor',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/logistica/producto',
    name: 'rt-logistica-producto',
    component: () => import('@/views/erp/logistica/producto/ProductoLogisticaList.vue'),
    meta: {
      resource: 'rt-logistica-producto',
      pageTitle: 'Producto',
      breadcrumb: [
        {
          text: 'Logística',
        },
        {
          text: 'Producto',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/logistica/motivo-movimiento',
    name: 'rt-logistica-motivo-movimiento',
    component: () => import('@/views/erp/logistica/motivo_movimiento/MotivoMovimientoList.vue'),
    meta: {
      resource: 'rt-logistica-motivo-movimiento',
      pageTitle: 'Motivos Movimiento',
      breadcrumb: [
        {
          text: 'Logística',
        },
        {
          text: 'Motivos Movimiento',

          active: true,
        },
      ],
    },
  },
  {
    path: '/app/logistica/movimiento',
    name: 'rt-logistica-movimiento',
    component: () => import('@/views/erp/logistica/movimiento/MovimientoList.vue'),
    meta: {
      resource: 'rt-logistica-movimiento',
      pageTitle: 'Movimiento',
      breadcrumb: [
        {
          text: 'Logística',
        },
        {
          text: 'Movimiento',

          active: true,
        },
      ],
    },
  },
  {
    path: '/app/logistica/movimientoform',
    name: 'rt-logistica-movimientoform',
    component: () => import('@/views/erp/logistica/movimiento/MovimientoForm.vue'),
    meta: {
      resource: 'rt-logistica-movimientoform',
      pageTitle: 'Logística',
      breadcrumb: [
        {
          text: 'Nueva Movimiento',
          active: true,
        },
      ],
    },
  },
]
